<template>
  <div class="background-style">
    <v-app>
      <Navbar :pageIn="1" />
      <v-main class="background-style">
        <v-container fluid>
          <section id="resumen" class="intro">
            <v-row>
              <v-col cols="12" lg="6" xl="6" class="left-side">
                <div class="center-text-vertical-left">
                  <h1 class="title-resume">Resumen de tu compra</h1>
                  <p>
                    Estás a unos pasos de completar tu reservación, este es el
                    resumen de todos los servicios que te llevas, dale un ojo a
                    tu paquete.
                  </p>

                  <div class="btn-siguiente">
                    <p class="title-btn-siguiente">Vale, todo correcto!</p>
                    <v-btn
                      height="auto"
                      width="auto"
                      text
                      @click="scrollToTheSection('contrato', 1)"
                    >
                      <v-icon size="100"> mdi-arrow-down-circle </v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" lg="6" xl="6" class="right-side">
                <div class="center-text-vertical">
                  <p class="text-right right-resume-title">
                    Lo que llevas hasta ahora...
                  </p>

                  <v-row>
                    <v-col cols="12" sm="6">
                      <p class="text-left right-resume-subtitle">
                        Paquete Basico
                      </p>
                      <div class="text-left right-resume-text">
                        <p>- Renta del salón</p>
                        <p>- Persona encargada de su evento</p>
                        <p>- Servicio de baños con insumos</p>
                        <p>- Mobiliario</p>
                        <p>- Alberca</p>
                        <p>- Pelotas para alberca</p>
                        <p>- Regadera con agua caliente</p>
                        <p>- Vestidores</p>
                        <p>- Brincolín jumbo</p>
                        <p>- Área de juegos</p>
                        <p>- Área de juegos</p>
                        <p>- Estacionamiento</p>
                        <p>- Zona para partir piñata</p>
                        <p>- Bocina con Bluetoot</p>
                      </div>
                    </v-col>

                    <v-col cols="12" sm="6">
                      <p class="text-left right-resume-subtitle">
                        Servicios Extras
                      </p>
                      <div class="text-left right-resume-text-other">
                        <p v-if="serviciosCount == 0" class="no-extras-text">
                          - Parece que no quieres ningun servicio extra
                        </p>
                        <div v-for="(id, index) in serviciosTemp" :key="index">
                          <p v-if="serviciosTemp[index]">
                            -{{ serviciosTemp[index].value }}
                          </p>
                        </div>
                        <p class="mt-5 payment-text">
                          Día del evento:
                          <span class="payment-number">
                            {{ reservationDayInWords }}
                          </span>
                        </p>
                        <p class="mt-5 payment-text">
                          Total a pagar:
                          <span class="payment-number">
                            {{ textPayment }}
                          </span>
                        </p>
                        <p class="payment-text-words">({{ PaymentInWords }})</p>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </section>
          <section id="contrato" class="contract" v-if="secondParallax">
            <v-row>
              <v-col cols="12" lg="6" xl="6" class="left-side">
                <div class="center-text-vertical-left">
                  <h1 class="title-resume">Solo un poco mas</h1>
                  <p>
                    ¡Necesitamos solo un poco de información para generar tu
                    contrato en línea, no te preocupes nada del otro mundo!
                  </p>

                  <div class="btn-siguiente">
                    <p class="title-btn-siguiente">Listo, a pagar!</p>
                    <v-btn
                      height="auto"
                      width="auto"
                      text
                      @click="validateInfoThenScrollTo('payment', 2)"
                    >
                      <v-icon size="100"> mdi-arrow-down-circle </v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-col>
              <v-col
                cols="12"
                lg="6"
                xl="6"
                class="right-side contract-right-side"
              >
                <div class="center-text-vertical">
                  <p class="text-right right-contract-title">
                    Rellena los datos...
                  </p>

                  <v-form v-model="valid">
                    <v-container>
                      <v-row>
                        <v-col cols="12" md="12">
                          <v-text-field
                            v-model="firstname"
                            :rules="nameRules"
                            :counter="50"
                            label="Nombre completo"
                            required
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="12">
                          <v-text-field
                            v-model="claveElector"
                            :rules="claveRules"
                            label="Clave de elector"
                            required
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="12">
                          <v-text-field
                            v-model="direccion"
                            :rules="direccionRules"
                            label="Direccion"
                            required
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="12">
                          <v-text-field
                            v-model="email"
                            :rules="emailRules"
                            label="Correo electronico"
                            required
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="12">
                          <v-text-field
                            v-model="reservationDayInWords"
                            label="Fecha del evento"
                            disabled
                            required
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-btn text @click="setPago()">
                            <v-checkbox disabled v-model="pagarEntero">
                            </v-checkbox>
                            Liquidar Monto Completo
                          </v-btn>
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-btn text @click="setPago()">
                            <v-checkbox disabled v-model="pagarMitad">
                            </v-checkbox>
                            Pagar el 50% como anticipo
                          </v-btn>
                        </v-col>

                        <v-col class="hidden-on-desktop mt-5" cols="12">
                          <v-btn
                            color="orange"
                            outlined
                            @click="validateInfoThenScrollTo('payment', 2)"
                          >
                            Proceder Al Pago!
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </div>
              </v-col>
            </v-row>
          </section>
          <section id="payment" class="payment" v-if="thirdParallax">
            <v-row>
              <v-col cols="12" lg="6" xl="6" class="left-side">
                <div class="center-text-vertical-left">
                  <h1 class="title-resume">Ultimo paso</h1>
                  <p>
                    Ahora solo queda realizar el pago, si el contrato es de
                    conformidad recuerda firmarlo y enviar el documento
                    escaneado a la encargada del evento
                  </p>
                  <div class="payment-spacer"></div>
                </div>
              </v-col>
              <v-col
                cols="12"
                lg="6"
                xl="6"
                class="right-side contract-right-side"
              >
                <div class="center-text-vertical">
                  <div id="pagarBox" class="card-body">
                    <div ref="paypal"></div>
                  </div>

                  <v-btn
                    block
                    color="primary"
                    elevation="2"
                    height="55"
                    @click="dialogDeposit = true"
                  >
                    PAGAR CON DEPÓSITO
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </section>
        </v-container>
      </v-main>
      <template>
        <div>
          <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="false"
            :paginate-elements-by-height="1400"
            :filename="'Contrato-'+transferUid"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="landscape"
            pdf-content-width="auto"
            @progress="onProgress($event)"
            @hasStartedGeneration="hasStartedGeneration()"
            @hasGenerated="hasGenerated($event)"
            ref="html2Pdf"
          >
            <section slot="pdf-content">
              <!-- PDF Content Here -->
              <!--
                                Divide your content into section, this pdf-item will
                                be the element that it's content will not be separated
                                in the paginating process. ex. <h4> and <span> wont be separated.
                            -->

              <!--
                                All other pdf-item will be separated in the pagination process,
                                depending on paginate-elements-by-height prop.
                            -->

              <div style="margin-top: 100px"></div>
              <section class="pdf-item pdf-text">
                <h4>
                  CONTRATO DE PRESTACIÓN DE SERVICIOS QUE CELEBRAN, POR UNA
                  PARTE, JARDÍN LA PEDRERA EN ADELANTE “LA EMPRESA” REPRESENTADO
                  POR DHALMA SAMANTHA CELIS GONZÁLEZ (celular 229 128 7973); Y
                  POR LA OTRA, {{ firstname }}, con clave de elector
                  {{ claveElector }}, con domicilio para efectos de este
                  contrato el ubicado en {{ direccion }}, A QUIEN EN LO SUCESIVO
                  SE LE DENOMINARÁ COMO “CONTRATANTE“, CONFORME AL TENOR DE LAS
                  SIGUIENTES:
                </h4>
              </section>
              <br />
              <section class="pdf-item pdf-text">
                <h4 class="center-text-pdf">CLÁUSULAS</h4>
              </section>
              <br />
              <section class="pdf-item pdf-text">
                <p>
                  <strong>PRIMERA.</strong> LA PARTE “CONTRATANTE” contrata los
                  servicios de “LA EMPRESA”, consistentes en la renta del jardín
                  para eventos ubicado en la calle Sin nombre número 5 interior
                  3, colonia El Porvenir, El Lencero, municipio de Emiliano
                  Zapata en el Estado de Veracruz.
                </p>
              </section>
              <br />
              <section class="pdf-item pdf-text">
                <p>
                  <strong>SEGUNDA.</strong> “LA EMPRESA” se obliga a
                  proporcionar el mobiliario consistente en diez mesas y 100
                  sillas, junto con la renta del inmueble. De igual forma se
                  podrá usar el servicio de baños, cocina (sin gas para cocinar)
                  y alberca de acuerdo con las condiciones contenidas en este
                  contrato.
                </p>
              </section>
              <br />
              <section class="pdf-item pdf-text">
                <p>
                  <strong>TERCERA</strong>. Las partes acuerdan que el evento
                  contratado se celebre en el lugar descrito en la cláusula
                  primera el día {{ reservationDayInWords }}, en un horario de
                  15 a 21 horas, con la precisión que la apertura para los
                  preparativos se realizará a partir de las 11.00 horas, y el
                  cierre del inmueble se realizará a las 21.00 horas, a efecto
                  de que se realicen las labores de limpieza y acomodo del
                  mobiliario.
                </p>
              </section>
              <br />
              <section class="pdf-item pdf-text" v-if="pagarMitad">
                <p>
                  <strong>CUARTA.</strong> “LA EMPRESA” recibirá por concepto de
                  pago, la cantidad de {{ textPayment }} ({{ PaymentInWords }}).
                  El 50% ({{ halfPayment }}) se pagará como anticipo y el resto
                  será cubierto un día antes de empezar con el servicio. El pago
                  será mediante pago en efectivo. En caso de que el evento no se
                  lleve a cabo por parte de la “CONTRATANTE”, no se reembolsará
                  el monto pagado. Solo procederá el cambio de fecha por una
                  ocasión, y previo aviso con 2 semanas de anticipación.
                </p>
              </section>
              <section class="pdf-item pdf-text" v-if="!pagarMitad">
                <p>
                  <strong>CUARTA.</strong> “LA EMPRESA” recibirá por concepto de
                  pago, la cantidad de {{ textPayment }} ({{ PaymentInWords }}).
                  En caso de que el evento no se lleve a cabo por parte de la
                  “CONTRATANTE”, no se reembolsará el monto pagado. Solo
                  procederá el cambio de fecha por una ocasión, y previo aviso
                  con 2 semanas de anticipación.
                </p>
              </section>
              <br />
              <section class="pdf-item pdf-text">
                <p>
                  <strong>QUINTA.</strong> Si el contrato no cumpliera su objeto
                  por cancelación de la parte “CONTRATANTE” se determina como
                  pena convencional el pago del 30% adicional al depósito o
                  apartado realizado a favor de “LA EMPRESA”. Por lo mismo, no
                  será reembolsable cantidad alguna.
                </p>
              </section>
              <br />
              <section class="pdf-item pdf-text">
                <p>
                  <strong>SEXTA.</strong> La parte “CONTRATANTE” se obliga a
                  responder por cualquier desperfecto que ocurra a las
                  instalaciones eléctricas, hidráulicas, de gas y sanitarias.
                  Asimismo, por afectaciones extraordinarias al área de jardín y
                  las plantas que lo conforman, así como al mobiliario que se
                  proporcione con la renta respectiva. El cálculo para la
                  indemnización correspondiente se realizará con el profesional
                  de la rama técnica que corresponda, a partir del presupuesto
                  que se presente al “CONTRATANTE”.
                </p>
              </section>
              <div class="html2pdf__page-break" />
              <div style="margin-top: 100px"></div>
              <br />
              <section class="pdf-item pdf-text">
                <p>
                  <strong>SÉPTIMA.</strong> El área de alberca se entregará
                  limpia y clorada para el uso ordinario de la parte
                  “CONTRATANTE”. La contaminación por residuos orgánicos e
                  inorgánicos de cualquier tipo, será motivo para generar un
                  costo mínimo extraordinario de limpieza de $500.00 (Quinientos
                  pesos 00/100). Asimismo, la parte “CONTRATANTE” asumirá la
                  responsabilidad que se genere por el mal uso de la alberca, ya
                  sea por accidente o negligencia de cuidado en el caso de
                  menores de edad. Por tanto, las partes acuerdan eximir a la
                  empresa de toda responsabilidad penal, administrativa o civil
                  que suscite con motivo de la atención por muerte o lesiones
                  que sufran los usuarios del servicio. De igual forma, se
                  obligan a cubrir los gastos que se generen para sacar a salvo
                  a “LA EMPRESA” de todo procedimiento de investigación, a nivel
                  de juicio y/o procedimiento administrativo.
                </p>
              </section>
              <br />
              <section class="pdf-item pdf-text">
                <p>
                  <strong>OCTAVA.</strong> El cierre de las instalaciones deberá
                  realizarse a las 21 horas y deberá estar en las condiciones
                  generales a las originalmente entregadas, salvo el uso
                  ordinario para cumplir el objeto de este contrato. Como
                  garantía el cliente acepta realizar un depósito de $500.00
                  (Quinientos pesos 00/100) al liquidar el monto del contrato.
                  Mismo que, en su caso, se devolverá al cierre del evento.
                </p>
              </section>
              <br />
              <section class="pdf-item pdf-text">
                <p>
                  <strong>NOVENA.</strong> En caso de que la parte “CONTRATANTE”
                  decida contratar hasta por una hora más el servicio, o por
                  alguna razón se prolongue la apertura del jardín después de la
                  hora límite, se generará un importe extraordinario de $500.00
                  (Quinientos pesos 00/100) y de $600.00 (Seiscientos pesos
                  00/100) más por la segunda. En ningún caso se permitirá la
                  continuación de la apertura después de las 23 horas, por lo
                  que las partes acuerdan el corte del suministro eléctrico sin
                  responsabilidad para ninguna.
                </p>
              </section>
              <br />
              <section class="pdf-item pdf-text">
                <p>
                  <strong>DÉCIMA.</strong> Para la interpretación y cumplimiento
                  del presente contrato, las partes se someten a la jurisdicción
                  y competencia de los tribunales de la Ciudad de Xalapa, del
                  Estado de Veracruz, así como a las disposiciones contenidas en
                  el código Civil vigente para el Estado de Veracruz,
                  renunciando expresamente al fuero que pudiera corresponderles
                  en razón de su domicilio actual o futuro.
                </p>
              </section>
              <br />
              <section class="pdf-item pdf-text">
                <p>
                  Leído el presente contrato y enteradas las partes de su
                  contenido y alcances lo firman por duplicado en la Ciudad de
                  Xalapa de Enríquez, Estado de Veracruz, el dia
                  {{ thisDay }}.
                </p>
              </section>
              <br />
              <br />
              <br />
              <section class="pdf-item pdf-text">
                <v-row>
                  <v-col cols="6">
                    <h1 class="center-text-pdf">“LA EMPRESA”</h1>
                  </v-col>
                  <v-col cols="6">
                    <h1 class="center-text-pdf">“CONTRATANTE”</h1>
                  </v-col>
                </v-row>
              </section>
              <br />
              <section class="pdf-item pdf-text">
                <v-row>
                  <v-col cols="6">
                    <h1 class="center-text-pdf">
                      DHALMA SAMANTHA CELIS GONZÁLEZ
                    </h1>
                  </v-col>
                  <v-col cols="6">
                    <h1 class="center-text-pdf">{{ firstname }}</h1>
                  </v-col>
                </v-row>
              </section>
              <br />
              <br />
              <br />
              <section class="pdf-item pdf-text">
                <v-row>
                  <v-col cols="6">
                    <h1 class="center-text-pdf">_________________________</h1>
                  </v-col>
                  <v-col cols="6">
                    <h1 class="center-text-pdf">_________________________</h1>
                  </v-col>
                </v-row>
              </section>
              <div class="html2pdf__page-break" v-if="pagarMitad" />
              <div style="margin-top: 100px" v-if="pagarMitad"></div>
              <section class="pdf-item pdf-text" v-if="pagarMitad">
                <p>
                  Por el presente pagaré reconozco y me obligo a pagar en esta
                  Ciudad o en cualquier otra parte donde se me requiera a la
                  orden de José Oliveros Ruiz, la cantidad de
                  {{ halfPayment }}, el día {{ reservationDayInWords }} Cantidad
                  que deberá pagarse y recibirse en efectivo a mi entera
                  satisfacción. La referida cantidad forma parte de una mayor,
                  por ende, queda expresamente convenido que si no es pagado
                  este documento precisamente a su vencimiento, su
                  incumplimiento queda sujeto a las disposiciones legales
                  vigentes. Este pagaré es mercantil y está regido por la Ley
                  General de Títulos y Operaciones de Crédito, en su artículo
                  173, parte final, así como los correlativos por no ser pagaré
                  domiciliado. De no verificarse el pago de la cantidad que este
                  pagaré contiene, el día de su vencimiento, se generará el
                  rédito del 15% mensual, por todo el tiempo que este insoluto,
                  sin perjuicio de los gastos y costas que se generan por el
                  cobro del mismo.
                </p>
              </section>
              <br />
              <br />
              <br />
              <section class="pdf-item pdf-text" v-if="pagarMitad">
                <v-row>
                  <v-col cols="6">
                    <h1 class="center-text-pdf">“LA EMPRESA”</h1>
                  </v-col>
                  <v-col cols="6">
                    <h1 class="center-text-pdf">“CONTRATANTE”</h1>
                  </v-col>
                </v-row>
              </section>
              <br />
              <section class="pdf-item pdf-text" v-if="pagarMitad">
                <v-row>
                  <v-col cols="6">
                    <h1 class="center-text-pdf">
                      DHALMA SAMANTHA CELIS GONZÁLEZ
                    </h1>
                  </v-col>
                  <v-col cols="6">
                    <h1 class="center-text-pdf">{{ firstname }}</h1>
                  </v-col>
                </v-row>
              </section>
              <br />
              <br />
              <br />
              <section class="pdf-item pdf-text" v-if="pagarMitad">
                <v-row>
                  <v-col cols="6">
                    <h1 class="center-text-pdf">_________________________</h1>
                  </v-col>
                  <v-col cols="6">
                    <h1 class="center-text-pdf">_________________________</h1>
                  </v-col>
                </v-row>
              </section>

              <!--
                                If you have any image with a remote source
                                set html2canvas.useCORS to true, although it is set to true by default
                                Ex.
                                html2canvas: {
                                    useCORS: true
                                }
                            -->
              <!-- <section class="pdf-item">
                                <img :src="remoteImageLink">
                            </section> -->
            </section>
          </vue-html2pdf>
        </div>
      </template>

      <vs-dialog blur v-model="dialogDeposit">
        <template #header>
          <h2 class="not-margin">
            <b>Pagar Con Transferencia / Depósito</b>
          </h2>
        </template>

        <div class="mt-2 con-form">
          <v-row>
            <v-col cols="12" md="6">
              <div
                class="text-left pl-1"
                style="text-align: left; margin-left: 5px; margin-bottom: 20px"
              >
                <strong>Banco: </strong>
                <span>Santander</span><br />
                <strong>Numero De Cuenta: </strong> <span> 65608700242</span>
                <br />
                <strong>CLABE: </strong> <span>014840655087002428</span>
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <span>Codigo de concepto: </span>
              <span style="color: red">
                <b> {{ transferUid }} </b>
              </span>

              <br />
            </v-col>
          </v-row>

          <div class="text-left">
            <vs-alert color="#fb8c00" style="text-align: left">
              Utiliza el código: <b> {{ transferUid }} </b> en el
              <b> concepto </b> de tú transferencia/depósito para que validemos
              tu pago, tendrás hasta 3 días a partir de hoy para realizar la
              transacción por: ${{ totalPayment }}.00 pesos, de lo contrario tu
              fecha se liberará y tu contrato quedará cancelado
            </vs-alert>
          </div>
        </div>

        <template #footer>
          <div class="footer-dialog">
            <vs-button block color="#2d6a4f" @click="setTemporalReservation()"> PAGAR CON ESTE MÉTODO </vs-button>
          </div>
        </template>
      </vs-dialog>

      <FooterOne />
    </v-app>
  </div>
</template>

<script src="https://cdn.jsdelivr.net/npm/vue@2.x/dist/vue.js"></script>
<script src="https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.js"></script>

<script>
import Navbar from "../components/navbar/navbar.vue";
import FooterOne from "../components/footer/footer.vue";
import swal from "prettyalert";
import "boxicons";

import { collection, addDoc, setDoc, getFirestore } from "firebase/firestore";

import VueHtml2pdf from "vue-html2pdf";

import { uid } from "uid";

export default {
  name: "PaymentTest",
  components: {
    Navbar,
    FooterOne,
    VueHtml2pdf,
  },
  data() {
    return {
      transferUid: "",
      dialogDeposit: false,
      validForPay: false,
      serviciosTemp: [],
      serviciosCount: null,
      totalPayment: 4950,
      textPayment: "$ 4950 MXN",
      testText: null,
      PaymentInWords: null,

      reservationDay: null,
      reservationDayInWords: null,
      usuario: [],

      secondParallax: false,
      thirdParallax: false,

      pagarMitad: false,
      pagarEntero: true,

      halfPayment: 0,
      thisDay: null,

      toPay: null,

      valid: false,
      firstname: "",
      direccion: "",
      claveElector: "",
      nameRules: [
        (v) => !!v || "Escriba su nombre completo",
        (v) => v.length <= 50 || "El nombre debe ser menor a 50 caracteres",
      ],
      claveRules: [
        (v) => !!v || "Escriba su clave de elector",
        (v) => v.length >= 5 || "La clave de elector debe ser valida",
      ],
      direccionRules: [
        (v) => !!v || "Escriba su direccion de elector",
        (v) => v.length >= 10 || "La direccion debe ser valida",
      ],
      email: "",
      emailRules: [
        (v) => !!v || "El correo no es valido",
        (v) => /.+@.+/.test(v) || "El correo no es valido",
      ],
    };
  },
  methods: {
    setLoaded() {
      this.loaded = true;
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  description: "Reservacion en Jardin La Pedrera",
                  amount: {
                    currency_code: "MXN",
                    value: this.toPay,
                  },
                },
              ],
            });
          },
          onApprove: async (data, actions) => {
            const order = await actions.order.capture();
            console.log(order);
            this.generateReport();

            const db = getFirestore();

            //await addDoc(collection(db, 'persons'), { name });

            await addDoc(collection(db, "reservacionesFechas"), {
              fecha: this.reservationDay,
              uid: this.usuario.uid,
              usuario: this.usuario.nombre,
              pagoMitad: this.pagarMitad,
              servicios: this.serviciosTemp,
            });

            swal({
              title: "Reservación lista",
              text: "Tu reservación ha sido agendada, nos estaremos viendo pronto!, si tienes alguna pregunta comunícate al 229 128 7973",
              icon: "success",
            }).then(function () {
              window.location.href = window.location.hostname;
              //router.push({ name: 'Home'})
            });
          },
          onError: (err) => {
            console.log(err);
          },
        })
        .render(this.$refs.paypal);
    },

    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },

    scrollToTheSection(id, parallax) {
      if (parallax == 1) {
        this.secondParallax = true;
        setTimeout(() => {
          document.getElementById(id).scrollIntoView({
            behavior: "smooth",
          });
        }, "500");
      }
      if (parallax == 2) {
        this.thirdParallax = true;
        setTimeout(() => {
          document.getElementById(id).scrollIntoView({
            behavior: "smooth",
          });
        }, "500");
      }
    },

    setPago() {
      this.pagarMitad = !this.pagarMitad;
      this.pagarEntero = !this.pagarEntero;
    },

    async validateInfoThenScrollTo(id, parallax) {
      if (this.pagarMitad) {
        this.toPay = this.totalPayment / 2;
      } else {
        this.toPay = this.totalPayment;
      }

      if (!this.valid) {
        swal(
          "Datos Incorrectos",
          "Algunos datos son incorrectos o no estan llenos por favor revisa nuevamente",
          "error"
        );
      } else {
        await this.scrollToTheSection(id, parallax);

        const pagarBox = document.getElementById("pagarBox");
        pagarBox.classList.remove("disabledbutton");

        const script = document.createElement("script");
        script.src =
          "https://www.paypal.com/sdk/js?client-id=Ad0fmAuwjbRk0r5vtavo-p5Ix681_vTGTJJOVJIsVZoqCxWsivUU2wsPtoFwC5m4cOU5C66j_v-7UQK9&currency=MXN";

        script.addEventListener("load", this.setLoaded);
        document.body.appendChild(script);

        this.firstname = this.firstname.toUpperCase();
        this.claveElector = this.claveElector.toUpperCase();
        //setTimeout(() => {this.generateReport()}, 500);
      }
    },

    async setTemporalReservation() {
      this.dialogDeposit = false;
      this.generateReport(); // MANDA A GENERAR EL CONTRATO CON LA INFORMACION

      const d = new Date();
      d.setDate(d.getDate() + 3);
      let time = d.getTime();

      const db = getFirestore();

      //await addDoc(collection(db, 'persons'), { name });

      await addDoc(collection(db, "reservacionesFechas"), {
        fecha: this.reservationDay,
        uid: this.usuario.uid,
        usuario: this.usuario.nombre,
        pagoMitad: this.pagarMitad,
        servicios: this.serviciosTemp,
        fechaCaducidad: time,
        status: "No Pagado",
        codigoDeposito: this.transferUid,
      });

      swal({
        title: "Reservación lista",
        text: "Tu reservación ha sido agendada, nos estaremos viendo pronto!, si tienes alguna pregunta comunícate al 229 128 7973",
        icon: "success",
      }).then(function () {
        window.location.href = window.location.hostname;
        router.push({ name: 'Home'})
      });
    },

    initialData() {
      this.transferUid = uid(6);
      this.serviciosTemp = JSON.parse(sessionStorage.getItem("servicios"));
      this.usuario = JSON.parse(sessionStorage.getItem("usuario"));
      this.serviciosCount = this.serviciosTemp.length;
      this.totalPayment = sessionStorage.getItem("totalPayment");
      this.reservationDay = sessionStorage.getItem("theDate");
      this.reservationDayInWords = sessionStorage.getItem("dateText");

      window.scrollTo(0, 0);

      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

      var convertir = require("numero-a-letras");
      this.PaymentInWords = convertir.NumerosALetras(this.totalPayment);

      const halfpay = this.totalPayment / 2;

      const d = new Date();
      this.thisDay =
        d.getDate().toString() +
        "-" +
        d.getMonth().toString() +
        "-" +
        d.getFullYear().toString();

      this.halfPayment = formatter.format(halfpay);
      this.testText = formatter.format(this.totalPayment);
      this.textPayment = this.testText + " MXN";

      //   const pagarBox = document.getElementById("pagarBox");
      //   pagarBox.classList.add("disabledbutton");

      // LIVE KEY: AeO4C5IQuD85b2jD-7DP0gYMlk76419bPAcQ0lSoFUvEPL-YnI_L5fzE0bu4Ftg8mFusrnTLY99chDMw
      // SANDBOX KEY: Ad0fmAuwjbRk0r5vtavo-p5Ix681_vTGTJJOVJIsVZoqCxWsivUU2wsPtoFwC5m4cOU5C66j_v-7UQK9
      //   const script = document.createElement("script");
      //   script.src =
      //     "https://www.paypal.com/sdk/js?client-id=Ad0fmAuwjbRk0r5vtavo-p5Ix681_vTGTJJOVJIsVZoqCxWsivUU2wsPtoFwC5m4cOU5C66j_v-7UQK9&currency=MXN";
      //   script.addEventListener("load", this.setLoaded);
      //   document.body.appendChild(script);
    },
  },
  mounted() {
    this.initialData();
  },
};
</script>

<style>
#zoid-paypal-buttons-uid_0e3dd26210_mja6mdu6nta > iframe.component-frame {
  z-index: 1;
}
</style>

<style scoped>
html {
  scroll-behavior: smooth;
}

.disabledbutton {
  pointer-events: none;
  opacity: 0.4;
}

.background-style {
  background-image: url("../assets/media/bgImg.png");
}

.left-side {
  min-height: 300px;
  background: rgba(73, 252, 212, 0.8);
}

.right-side {
  min-height: 300px;
  background: #fff;
}

.title-resume {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 4rem;
  font-weight: 700;
  letter-spacing: -0.05em;
  line-height: 1.1;
  margin: 0 0 1.5rem 0;
  text-transform: none;
}

.center-text-vertical {
  margin-top: 80px;
  margin-bottom: 100px;
  margin-left: 10%;
  margin-right: 10%;
}

.center-text-vertical-left {
  margin-top: 230px;
  margin-bottom: 100px;
  margin-left: 10%;
  margin-right: 10%;
}

.hidden-on-desktop {
  display: none;
}

.payment-spacer {
  margin-top: 300px;
}

@media only screen and (max-width: 600px) {
  .center-text-vertical-left {
    margin-top: 100px;
    margin-bottom: 100px;
    margin-left: 10%;
    margin-right: 10%;
  }

  .hidden-on-desktop {
    display: block;
  }

  .payment-spacer {
    margin-top: 100px;
  }
}

.right-resume-title {
  font-family: "Kanit", sans-serif;
  font-size: 2.2rem;
  font-weight: 800;
}

.right-contract-title {
  font-family: "Kanit", sans-serif;
  font-size: 2.2rem;
  font-weight: 800;
}

.contract-right-side {
  margin-top: 80px;
}

.right-resume-subtitle {
  font-family: "Kanit", sans-serif;
  font-size: 1.8rem;
  font-weight: 300;
}

.right-resume-text {
  font-family: "Kanit", sans-serif;
  font-size: 1rem;
  font-weight: 300;
  margin-left: 20px;
}

.right-resume-text-other {
  font-family: "Kanit", sans-serif;
  font-size: 1rem;
  font-weight: 300;
  margin-left: 20px;
}

.right-resume-text-other p {
  margin-bottom: 1px !important;
}

.btn-siguiente {
  margin-top: 100px;
}

.title-btn-siguiente {
  font-family: "Kanit", sans-serif;
  font-weight: 900;
  font-size: 1.5rem;
  color: #271f30;
}

.payment-text {
  font-family: "Kanit", sans-serif;
  font-weight: 800;
  font-size: 1.5rem;
  color: #271f30;
}

.payment-number {
  font-family: "Kanit", sans-serif;
  font-weight: 400;
  font-size: 1.2rem;
  color: #271f30;
  margin-left: 10px;
}

.payment-text-words {
  font-family: "Kanit", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  color: #271f30;
}

.no-extras-text {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  color: darkslategrey;
}

.date-text {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1rem;
  font-weight: 800;
  color: #271f30;
}

.date-text-date {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
  color: #271f30;
}

.pdf-text {
  margin-left: 100px;
  margin-right: 100px;
  text-align: justify;
}

.card-body {
  margin-bottom: 25px;
}

.center-text-pdf {
  text-align: center !important;
}
</style>
