<template>
  <div class="reservation">
    <v-app id="reservation">
      <Navbar :pageIn="1"/>
      <v-main>
        <v-card flat class="float-right hidden-sm-and-down">
          <template> 
            <div>
              <div class="datepicker-inline-trigger">
                <input
                class="d-none"
                  type="text"
                  id="datepicker-inline-trigger"
                  placeholder="Select dates"
                  :value="formatDates(dateOne)"
                >
                <AirbnbStyleDatepicker
                  :trigger-element-id="'datepicker-inline-trigger'"
                  :mode="'single'"
                  :fullscreen-mobile="false"
                  :inline= "true"
                  :closeAfterSelect="false"
                  :dateOne= "dateOne"
                  :minDate= "minDate"
                  :monthsToShow= 1
                  :showShortcutsMenuTrigger= "false"
                  :disabledDates = "noFreeDates"

                  @date-one-selected="val => { dateOne = val }" 
                />
              </div>
            </div>
            <div class="mt-2" style="font-family: 'Anton', sans-serif; font-size: 18px" v-if="reservacionPaquete||reservacionBase">
              <h4>Total: {{totalText}}</h4>
            </div>
            <v-btn
            dark
            color="#1B4332"
            class="reservation-btn mt-2 font-reservation"
            @click="setReservation()"
            >
              RESERVA YA!
            </v-btn>
          </template>
        </v-card>
         <section id="reser">
          <div class="py-5"></div>
          <v-container>
            <h2 class="display-2 font-weight-bold mb-3">RESERVA TU EVENTO</h2>
            <v-responsive class="mx-auto mb-8" width="56">
              <v-divider class="mb-1"></v-divider>
              <v-divider></v-divider>
            </v-responsive>

            
            <v-row 
            >

            <!-- CARDS HERE-->

            <v-col md="4" lg="4" xl="5">
              <v-card>
                <v-card class="mx-auto"
                  width="100%"
                  height="auto"
                  color="#d8f3dc"
                  hover
                  link
                  @click="selectOption('paquete'),$vuetify.goTo('#opcionesPaquetes');">
                  <v-img
                    src="https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FAssets%2Fddf.jpg?alt=media&token=b24f638f-eed3-4af1-a38a-3d902a3aa167"
                    height="200px"
                  ></v-img>      
                  <v-card-actions> 
                    <v-card-subtitle>
                      <span class="font-card-reservation" style="font-size: 20px!important">PLANEA TU RESERVACION</span> <br>
                      <v-responsive class="text-left sub-font-card-reservation mt-2">Escoge servicios adicionales despreocúpate de tener que organizar la fiesta, selecciona lo que necesites y deja que nosotros nos encarguemos</v-responsive>
                    </v-card-subtitle>             
                    <v-spacer></v-spacer>
                    <v-checkbox
                      v-model="reservacionPaquete"
                    ></v-checkbox>
                  </v-card-actions>
                </v-card>
              </v-card>

            </v-col>
            

            <v-col md="4" lg="4" xl="5">
               <v-card>
                <v-card class="mx-auto"
                  width="100%" height="100%"
                  color="#d8f3dc"
                  hover
                  link
                  @click="selectOption('base')">
                  <v-img
                    src="https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FAssets%2Fddf2.jpg?alt=media&token=5da82c8a-41f7-4f35-a1f0-5e4eb8baf986"
                    height="200px"
                  ></v-img>      
                  <v-card-actions> 
                    <v-card-subtitle>
                      <span class="font-card-reservation" style="font-size: 20px!important">RESERVACION BASICA</span> <br>
                      <v-responsive class="text-left sub-font-card-reservation mt-2">¿Te gusta organizar las cosas por ti mismo? ¡Si deseas planificar tu evento desde 0 este es tu paquete, te damos lo justo para que te la pases genial!</v-responsive>
                    </v-card-subtitle>             
                    <v-spacer></v-spacer>
                    <v-checkbox
                      v-model="reservacionBase"
                    ></v-checkbox>
                  </v-card-actions>
                </v-card>
              </v-card>
            </v-col>
            <v-col md="4" lg="2"></v-col>
              <!-- END OF CARDS-->
            </v-row>

            <section id="opcionesPaquetes" class="float-resumen">
              <v-row >
              <v-col cols="12" md="8" lg="8" xl="5" class="hidden-on-phone">
                <v-card class="mx-auto"
                width="100%"
                color= white
                elevation="10"
                hover
                
                >            
                  <v-card-title class="font-reservation-bottom " @click="(showPlaning = !showPlaning),(setPaquete())">
                    ARMA TU PAQUETE
                  </v-card-title>             
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-btn
                      icon
                      @click="(showPlaning = !showPlaning),(setPaquete())"
                      class="mx-7 mb-2"
                    >
                      <v-icon color="black">{{ showPlaning ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                    </v-btn>
                  </v-row>
              
                  <v-expand-transition >
                    <div v-show="showPlaning">
                      <div class="mt-3"></div>
                      <v-row>
                        <!-- SERVICIOS CARDS -->

                        <v-col cols="12">
                          <v-card
                          class="mx-5"  
                          width="auto "
                          elevation="6"
                          >
                          <v-card-title class="font-reservation-bottom" @click="servicios1 = !servicios1">
                            ENTRETENIMIENTO
                            <v-spacer></v-spacer>
                            <v-btn
                              icon
                              class="mx-7 mb-2"
                            >
                              <v-icon color="black">{{ servicios1 ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                            </v-btn>
                          </v-card-title>
                          <v-row> 
                          </v-row>
                          <v-expand-transition >
                            <div v-show="servicios1">
                              <v-row justify="space-around">
                                <v-col cols="10">

                                  <v-card shaped hover color="#3D3D3D" @click="checkTheBox(1,index)" class="my-1"  v-for="(id,index) in servicios1Box" :key="index">
                                    <v-list-item two-line>
                                      <v-list-item-action>
                                        <v-checkbox color="#FFFF" v-model="servicios1Box[index].state" dark disabled />
                                      </v-list-item-action>
                                      <v-list-item-content>
                                        <v-list-item-title class="text-start mb-1 text-card-info"> {{servicios1Box[index].value}}</v-list-item-title> 
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-card>

                                </v-col>
                              </v-row>
                            </div>
                          </v-expand-transition>   
                          </v-card>
                        </v-col>

                        <v-col cols="12">
                          <v-card
                          class="mx-5"  
                          width="auto "
                          elevation="6"
                          >
                          <v-card-title class="font-reservation-bottom" @click="servicios2 = !servicios2">
                            COMIDA
                            <v-spacer></v-spacer>
                            <v-btn
                              icon
                              
                              class="mx-7 mb-2"
                            >
                              <v-icon color="black">{{ servicios2 ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                            </v-btn>
                          </v-card-title>
                          <v-row> 
                          </v-row>
                          <v-expand-transition >
                            <div v-show="servicios2">
                              <v-row justify="space-around">
                                <v-col cols="10">

                                  <v-card shaped hover color="#3D3D3D" @click="checkTheBox(2,index)" class="my-1"  v-for="(id,index) in servicios2Box" :key="index">
                                    <v-list-item two-line>
                                      <v-list-item-action>
                                        <v-checkbox color="#FFFF" v-model="servicios2Box[index].state" dark disabled />
                                      </v-list-item-action>
                                      <v-list-item-content>
                                        <v-list-item-title class="text-start mb-1 text-card-info"> {{servicios2Box[index].value}}</v-list-item-title> 
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-card>

                                </v-col>
                              </v-row>
                            </div>
                          </v-expand-transition>   
                          </v-card>
                        </v-col>

                        <v-col cols="12">
                          <v-card
                          class="mx-5"  
                          width="auto "
                          elevation="6"
                          >
                          <v-card-title class="font-reservation-bottom" @click="servicios3 = !servicios3">
                            MOBILIARIO
                            <v-spacer></v-spacer>
                            <v-btn
                              icon
                              class="mx-7 mb-2"
                            >
                              <v-icon color="black">{{ servicios3 ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                            </v-btn>
                          </v-card-title>
                          <v-row> 
                          </v-row>
                          <v-expand-transition >
                            <div v-show="servicios3">
                              <v-row justify="space-around">
                                <v-col cols="10">

                                  <v-card shaped hover color="#3D3D3D" @click="checkTheBox(3,index)" class="my-1"  v-for="(id,index) in servicios3Box" :key="index">
                                    <v-list-item two-line>
                                      <v-list-item-action>
                                        <v-checkbox color="#FFFF" v-model="servicios3Box[index].state" dark disabled />
                                      </v-list-item-action>
                                      <v-list-item-content>
                                        <v-list-item-title class="text-start mb-1 text-card-info"> {{servicios3Box[index].value}}</v-list-item-title> 
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-card>

                                </v-col>
                              </v-row>
                            </div>
                          </v-expand-transition>     
                          </v-card>
                        </v-col>

                        <v-col cols="12">
                          <v-card
                          class="mx-5"  
                          width="auto "
                          elevation="6"
                          >
                          <v-card-title class="font-reservation-bottom" @click="servicios4 = !servicios4">
                            DECORACIÓN
                            <v-spacer></v-spacer>
                            <v-btn
                              icon
                              class="mx-7 mb-2"
                            >
                              <v-icon color="black">{{ servicios4 ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                            </v-btn>
                          </v-card-title>
                          <v-row> 
                          </v-row>
                          <v-expand-transition >
                            <div v-show="servicios4">
                              <v-row justify="space-around">
                                <v-col cols="10">

                                  <v-card shaped hover color="#3D3D3D" @click="checkTheBox(4,index)" class="my-1"  v-for="(id,index) in servicios4Box" :key="index">
                                    <v-list-item two-line>
                                      <v-list-item-action>
                                        <v-checkbox color="#FFFF" v-model="servicios4Box[index].state" dark disabled />
                                      </v-list-item-action>
                                      <v-list-item-content>
                                        <v-list-item-title class="text-start mb-1 text-card-info"> {{servicios4Box[index].value}}</v-list-item-title> 
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-card>

                                </v-col>
                              </v-row>
                            </div>
                          </v-expand-transition>     
                          </v-card>
                        </v-col>

                        <v-col cols="12">
                          <v-card
                          class="mx-5"  
                          width="auto "
                          elevation="6"
                          >
                          <v-card-title class="font-reservation-bottom" @click="servicios5 = !servicios5">
                            PERSONAL DE APOYO
                            <v-spacer></v-spacer>
                            <v-btn
                              icon
                              class="mx-7 mb-2"
                            >
                              <v-icon color="black">{{ servicios5 ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                            </v-btn>
                          </v-card-title>
                          <v-row> 
                          </v-row>
                          <v-expand-transition >
                            <div v-show="servicios5">
                              <v-row justify="space-around">
                                <v-col cols="10">

                                  <v-card shaped hover color="#3D3D3D" @click="checkTheBox(5,index)" class="my-1"  v-for="(id,index) in servicios5Box" :key="index">
                                    <v-list-item two-line>
                                      <v-list-item-action>
                                        <v-checkbox color="#FFFF" v-model="servicios5Box[index].state"  dark disabled />
                                      </v-list-item-action>
                                      <v-list-item-content>
                                        <v-list-item-title class="text-start mb-1 text-card-info"> {{servicios5Box[index].value}}</v-list-item-title> 
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-card>

                                </v-col>
                              </v-row>
                            </div>
                          </v-expand-transition>     
                          </v-card>
                        </v-col>
                      </v-row>
                    </div>
                  </v-expand-transition>
                  <v-row class="my-15"></v-row>
                </v-card>
              </v-col>

              <v-col cols="12" md="8" lg="8" xl="5" >
                <v-card class="mx-auto mt-8"
                width="100%"
                color= "#A5CBC3"
                hover
                >  
                <v-row>
                  <v-col cols="2">
                    <v-btn
                      icon
                      @click="serviciosPaquete = !serviciosPaquete"
                    >
                      <v-icon color="white">{{ serviciosPaquete ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="10">
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title class="font-reservation-bottom text-end" style="color: white"> 
                          MI RESERVACIÓN
                        </v-list-item-title>
                        <v-list-item-subtitle class="font-reservation-bottom text-end" style="color: white">Lo que incluye mi paquete</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>

                <v-expand-transition >
                  <div v-show="serviciosPaquete">
                    <v-row>
                      <v-col cols="12">
                        <v-card
                        class="mx-5"  
                        width="auto"
                        color="transparent"
                        elevation="0"
                        >
                          <v-card-text>
                           
                            <v-bottom-sheet
                              v-model="sheet"
                              inset
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn v-for="(id,index) in serviciosBasicos" :key="index" v-bind="attrs"
                                  v-on="on" class="mx-1 my-1" small outlined @click="ShowBasicService(index)"> {{serviciosBasicos[index].value}} 
                                </v-btn>
                              </template>
                              <v-sheet
                                class="text-center"
                                height="800px"
                              >
                                <v-btn
                                  class="mt-6"
                                  text
                                  color="error"
                                  @click="sheet = !sheet"
                                  rounded
                                >
                                  <v-icon>
                                    mdi-close
                                  </v-icon>
                                </v-btn>
                                <div class="my-3">
                                  <p> {{displayText}}</p>
                                </div>
                                <div class="my-3">
                                  <v-img
                                    :src="displayImage"
                                    width="auto"
                                    max-height="700px"
                                    alt="img-servicio"
                                  ></v-img>
                                </div>
                              </v-sheet>
                            </v-bottom-sheet>
                            <p class="my-1 font-reservation-bottom">----- Servicios Adicionales -----</p>
                            <p class="my-1 " style="color: red" v-if="reservacionPaquete==false">Selecciona planear tu reservacion para poder tener servicios adicionales</p>

                            <v-row v-if="reservacionPaquete==true" class="my-1" justify="space-around">

                              <div v-for="(id,index) in paqueteArmadoResult" :key="index">
                                <v-btn class="mx-1 my-1" small outlined  @click="ShowPlusService(index)" v-if="paqueteArmadoResult[index]"> {{paqueteArmadoResult[index].value}} </v-btn>
                              </div>
                              
                 
                            </v-row>
                          </v-card-text> 
                        </v-card>
                      </v-col>
                    </v-row>
                  </div>
                </v-expand-transition> 
                </v-card>
              </v-col>
              <v-col md="4"></v-col>
            </v-row>
            </section>

            
          </v-container>
          
          
        </section>  
      </v-main>
      <v-card
      elevation="6"
        width="100%" 
        height="120px" 
        class="d-xs-flex .d-sm-flex d-md-none d-lg-none float-bottom"
        color="#2d6a4f"
      >
        <template>
          <div>
            <v-row class="datepicker-trigger">
              <v-col cols="12" class="mt-6">
                <input
                  class="input-calendar"
                  type="text"
                  :placeholder="theDate"
                  id="datepicker-trigger"
                  :value="formatDates(dateOne)"
                >
              </v-col>
              <AirbnbStyleDatepicker
                  class="date-picker-mobile-margins"
                  :trigger-element-id="'datepicker-trigger'"
                  :mode="'single'"
                  :fullscreen-mobile="true"
                  :closeAfterSelect="true"
                  :minDate= "minDate"
                  :monthsToShow= 1
                  :showShortcutsMenuTrigger= "false"
                  :disabledDates = "noFreeDates"

                  @date-one-selected="val => { dateOne = val }" 
                />
            </v-row>
          </div>
        </template>
        <h3>Total: {{totalText}}</h3>
        <v-btn
          dark
          plain
          color="white"
          class="reservation-btn mt-2 font-reservation-bottom"
          @click="setReservation()"
          >
            RESERVAR YA!
          </v-btn>
      </v-card>
      
      <FooterOne/>
      <div class="mb-12 d-xs-flex .d-sm-flex d-md-none d-lg-none"></div>
    </v-app>
  </div>
</template>

<script src="https://cdn.jsdelivr.net/npm/vue@2.x/dist/vue.js"></script>
<script src="https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.js"></script>

<script>


// import {
//   getAuth,
// } from "firebase/auth";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { COLORS } from "@/assets/theme/colors.js";
import Navbar from "../components/navbar/navbar.vue";
import FooterOne from "../components/footer/footer.vue";
import swal from "prettyalert";

//DatePicker\
import format from 'date-fns/format'

export default {
  name: "reservation",
  components: {
    Navbar,
    FooterOne,
  },
  data(){
    return{
      colors: COLORS,
      dateFormat: 'dddd, MMMM D, YYYY',
      dateOne: '',
      dateOneMonth: '',
      dateTwo: '',
      minDate: '',
      sheet: false,

      dateValidator: 0,
      
      displayImage: "",
      displayText: "",

      selectedDay: '',
      noFreeDates:[],

      reservacionPaquete: false,
      reservacionBase: false,

      showPlaning: false,

      total: 0,
      totalText: '',
      theDate: "Selecciona una fecha",

      serviciosPaquete: false,

      servicios1: false,
      servicios2: false,
      servicios3: false,
      servicios4: false,
      servicios5: false,

      serviciosBasicos: [
        { id: 0 , state: false , value: "Salón", descrip:"Aqui va la descripcion de el salon agregando los comentarios que quieran poner sobre el.. intentando que no sea tan larga" , img: "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FServicios%2FSalon%2FSalon1.jpg?alt=media&token=454af4db-4067-47d2-887e-8f913ec02f8f"},
        { id: 1 , state: false , value: "Mobiliario", descrip:"" , img: "" },
        { id: 2 , state: false , value: "Alberca", descrip:"" , img: ""},
        { id: 3 , state: false , value: "Vestidores", descrip:"" , img: ""},
        { id: 4 , state: false , value: "Regadera con agua caliente", descrip:"" , img: ""},
        { id: 5 , state: false , value: "Pelotas para alberca", descrip:"" , img: ""},
        { id: 6 , state: false , value: "Brincolín jumbo", descrip:"" , img: ""},
        { id: 7 , state: false , value: "Área de juegos", descrip:"Aqui va la descripcion de el area de juegos agregando los comentarios que quieran poner sobre el.. intentando que no sea tan larga" , img: "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FServicios%2FSalon%2FJardin1.jpg?alt=media&token=e546a957-63f0-477b-bd8c-0d83809a52e1"},
        { id: 8 , state: false , value: "Estacionamiento", descrip:"" , img: ""},
        { id: 9 , state: false , value: "Zona para partir piñata", descrip:"" , img: ""},
        { id: 10 , state: false , value: "Bocina con Bluetoot", descrip:"" , img: ""},
        { id: 11 , state: false , value: "Servicio de baños con insumos", descrip:"" , img: ""},
        { id: 12 , state: false , value: "Persona encargada de su evento", descrip:"" , img: ""},
      ],

      

      servicios1Box: [
        { id: 0 , state: false , value: "Show de payasos", price: 3250, absolutePlace: 0 , descrip:"Show de payasos para niños con concursos y premios." , img: "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FServicios%2FEntretenimiento%2Fentretenimiento1.jpg?alt=media&token=4c651b69-a23a-457f-afb2-0fd237104987"},
        //{ id: 1 , state: false , value: "Globoflexia", price: 5200, absolutePlace: 1 , descrip:"" , img: "" },
        { id: 1 , state: false , value: "Magia", price: 5200, absolutePlace: 1 , descrip:"Show de magia clásica, se presentan diferentes efectos como bastones, palomas, plumas, juegos de magia hablados e interactuados." , img: "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FServicios%2FEntretenimiento%2Fentre-magia.jpg?alt=media&token=074b0c5d-c5cd-4570-865c-598bd2aae3e4"},
        { id: 2 , state: false , value: "Pinta caritas", price: 1800, absolutePlace: 2 , descrip:"Servicio de pinta caritas con diferentes diseños para niños." , img: "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FServicios%2FEntretenimiento%2Fentre-pintacaritas.jpg?alt=media&token=209154d7-e0f5-4c33-9598-9312347d2c5a"},
        { id: 3 , state: false , value: "Botargas", price: 2500, absolutePlace: 3 , descrip:"Botargas 300 botargas diferentes, concursos, coreografía, coordinación de pastel y piñatas, micrófono inalámbrico, 3 horas de servicio 2 personas." , img: "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FServicios%2FEntretenimiento%2Fentre-botargas.jpg?alt=media&token=82ca4c89-0aa0-4a3b-9943-8d04a59b4495"},
        { id: 4 , state: false , value: "Inflables", price: 1100, absolutePlace: 4 , descrip:"Inflables diferentes tamaños grandes, medianos, y chicos. " , img: "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FServicios%2FEntretenimiento%2Fentre-inflables.jpg?alt=media&token=a611a78d-fe83-4dfd-96be-eee4b70a6a81"},
        { id: 5 , state: false , value: "Dj", price: 4000, absolutePlace: 5 , descrip:"6 horas de servicio, con distinto equipamiento de acuerdo con las necesidades amenización de comida y baile. " , img: "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FServicios%2FEntretenimiento%2Fentre-dj.jpg?alt=media&token=f9104fd8-cc7b-496c-a037-6e5f49e35501"},
      ],

      servicios2Box: [
        { id: 10 , state: false , value: "Taquiza", price: 5000, absolutePlace: 10 , descrip:"Se proporcionan de 1 a 2 órdenes de tacos acompañados de salsas, vegetales, desechables, limones, etc. Y personal asistiendo evento." , img: "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FServicios%2FComida%2Fcomida-taquiza.jpg?alt=media&token=562a3ffb-0201-477e-84c7-2c885a10a9f5"},
        //{ id: 11 , state: false , value: "Platillo Infantil", price: 200, absolutePlace: 11 , descrip:"" , img: ""},
        //{ id: 12 , state: false , value: "Cazuelas", price: 1500, absolutePlace: 12 , descrip:"Servicio de cazuela de guisados; 5 guisados diferentes, 2 guarniciones a elegir, 2 vitroleras de agua, personal para servir. " , img: ""},
        { id: 11 , state: false , value: "Pastel", price: 1500, absolutePlace: 11 , descrip:"Pastel con diferentes diseños, diferentes rellenos, personalizados para 50 o 110 personas. " , img: "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FServicios%2FComida%2Fcomida-pastel.jpg?alt=media&token=afce1b17-6497-42ca-87a7-f62849885538"},
        //{ id: 14 , state: false , value: "Gelatina", price: 544, absolutePlace: 14 , descrip:"" , img: ""},
        { id: 12 , state: false , value: "Mesa de Dulces", price: 3200, absolutePlace: 12 , descrip:"Mesa de dulces, personalizadas dependiendo de la temática, dulces variados y frutas de temporadas. " , img: "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FServicios%2FComida%2Fcomida-mesa-dulces.jpg?alt=media&token=857fd461-0066-4838-8380-fdca65e70131"},
        { id: 13 , state: false , value: "Refrescos o Aguas", price: 3500, absolutePlace: 13 , descrip:"Servicio de refresco ilimitado para todo tu evento dependiendo el numero de personas. " , img: "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FServicios%2FComida%2Fcomida-refrescos.jpg?alt=media&token=c033b526-7126-4ff1-b76e-7eb855c618f9"},
      ],

      servicios3Box: [
        //{ id: 20 , state: false , value: "Mesas", price: 986, absolutePlace: 20 , descrip:"" , img: ""},
        //{ id: 21 , state: false , value: "Sillas", price: 876, absolutePlace: 21 , descrip:"" , img: ""},
        { id: 22 , state: false , value: "Mantelería", price: 60, absolutePlace: 22 , descrip:"" , img: "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FServicios%2FMobiliario%2Fmobiliario-manteleria.jpg?alt=media&token=abfdcca0-7295-458c-95f9-188dc540f605"},
        //{ id: 23 , state: false , value: "Loza", price: 764, absolutePlace: 23 , descrip:"" , img: ""},
      ],

      servicios4Box: [
        { id: 30 , state: false , value: "Globos", price: 1300, absolutePlace: 30 , descrip:"Diferentes modelos de decoración y materiales de acuerdo a la temática de su evento" , img: "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FServicios%2FDecoracion%2Fdecoracion-globos.jpg?alt=media&token=1ab45298-0e1f-469a-b3ea-3ddef9e67b05"},
        { id: 31 , state: false , value: "Piñatas", price: 250, absolutePlace: 31 , descrip:"Piñatas todas las temáticas diferentes tamaños y costos. " , img: "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FServicios%2FDecoracion%2Fdecoracion-pinatas.jpg?alt=media&token=5bcd8e18-4175-47e4-9df8-a08a5dca1f19"},
        { id: 32 , state: false , value: "Centros De Mesa", price: 100, absolutePlace: 32 , descrip:"" , img: "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FServicios%2FDecoracion%2Fdecoracion-centros-mesa.jpg?alt=media&token=d5222759-d9eb-4118-b366-ad9023757c4f"},
      ],

      servicios5Box: [
        { id: 40 , state: false , value: "Meseros", price: 0, absolutePlace: 40 , descrip:"" , img: "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FServicios%2FPersonal-Apoyo%2FPersonal-meseros.jpg?alt=media&token=e55a50a1-1a43-42a8-93fd-59287a86a862"},
        { id: 41 , state: false , value: "Hostess", price: 0, absolutePlace: 41 , descrip:"" , img: "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FServicios%2FPersonal-Apoyo%2FPersonal-meseros.jpg?alt=media&token=e55a50a1-1a43-42a8-93fd-59287a86a862"},
        { id: 42 , state: false , value: "Valet Parking", price: 0, absolutePlace: 42 , descrip:"" , img: "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FServicios%2FPersonal-Apoyo%2FPersonal-meseros.jpg?alt=media&token=e55a50a1-1a43-42a8-93fd-59287a86a862"},
      ],  

      paqueteArmadoResult: [],


      

    }
  },
  mounted(){
    this.getFirstDate();
    this.getNoFreeDates();
  },
  methods: {

    setReservation(){
      sessionStorage.setItem("totalPayment", this.total);
      sessionStorage.setItem("dateText", this.theDate);
      sessionStorage.setItem("theDate", this.selectedDay);
      sessionStorage.setItem("servicios", JSON.stringify(this.paqueteArmadoResult));
      if(this.selectedDay == ''){
        swal("Sin fecha", "No has seleccionado una fecha para realizar tu reservación", "error");
      }else{
        if(this.total == 0){
          swal("Sin Paquete", "Debes seleccionar el tipo de paquete que deseas contratar", "error");
        }else{
          this.$router.push({ name: 'payment'});
        } 
      }    
    },

    ShowBasicService(index){
      this.displayImage = "";
      this.displayText = "";
      this.displayImage = this.serviciosBasicos[index].img;
      this.displayText = this.serviciosBasicos[index].descrip;
    },

    ShowPlusService(index){
      console.log("clicked on: ",index);
      this.sheet = !this.sheet;
      this.displayImage = "";
      this.displayText = "";
      this.displayImage = this.paqueteArmadoResult[index].img;
      this.displayText = this.paqueteArmadoResult[index].descrip;
    },

    checkTheBox(servicio,index){
      switch(servicio){
        case 1: 
          this.servicios1Box[index].state = !this.servicios1Box[index].state;
          if(this.servicios1Box[index].state == true){
            this.paqueteArmadoResult[this.servicios1Box[index].absolutePlace] = this.servicios1Box[index];
            this.total = this.total + this.servicios1Box[index].price;
          }else{
            this.paqueteArmadoResult[this.servicios1Box[index].absolutePlace] = null;
            this.total = this.total - this.servicios1Box[index].price;
          }
          this.totalText = "$" + this.total.toString() + " MXN";
          break;
        case 2:
          this.servicios2Box[index].state = !this.servicios2Box[index].state;
          if(this.servicios2Box[index].state == true){
            this.paqueteArmadoResult[this.servicios2Box[index].absolutePlace] = this.servicios2Box[index];
            this.total = this.total + this.servicios2Box[index].price;
          }else{
            this.paqueteArmadoResult[this.servicios2Box[index].absolutePlace] = null;
            this.total = this.total - this.servicios2Box[index].price;
          }
          this.totalText = "$" + this.total.toString() + " MXN";
          break;
        case 3:
          this.servicios3Box[index].state = !this.servicios3Box[index].state;
          if(this.servicios3Box[index].state == true){
            this.paqueteArmadoResult[this.servicios3Box[index].absolutePlace] = this.servicios3Box[index];
            this.total = this.total + this.servicios3Box[index].price;
          }else{
            this.paqueteArmadoResult[this.servicios3Box[index].absolutePlace] = null;
            this.total = this.total - this.servicios3Box[index].price;
          }
          this.totalText = "$" + this.total.toString() + " MXN";
          break;
        case 4:
          this.servicios4Box[index].state = !this.servicios4Box[index].state;
          if(this.servicios4Box[index].state == true){
            this.paqueteArmadoResult[this.servicios4Box[index].absolutePlace] = this.servicios4Box[index];
            this.total = this.total + this.servicios4Box[index].price;
          }else{
            this.paqueteArmadoResult[this.servicios4Box[index].absolutePlace] = null;
            this.total = this.total - this.servicios4Box[index].price;
          }
          this.totalText = "$" + this.total.toString() + " MXN";
          break;
        case 5:
          this.servicios5Box[index].state = !this.servicios5Box[index].state;
          if(this.servicios5Box[index].state == true){
            this.paqueteArmadoResult[this.servicios5Box[index].absolutePlace] = this.servicios5Box[index];
            this.total = this.total + this.servicios5Box[index].price;
          }else{
            this.paqueteArmadoResult[this.servicios5Box[index].absolutePlace] = null;
            this.total = this.total - this.servicios5Box[index].price;
          }
          this.totalText = "$" + this.total.toString() + " MXN";
          break;
      }

    },

    setPaquete(){
      if(this.showPlaning == true){
        this.selectOption('paquete');
      }
    },


    CleanSelections(){
      this.paqueteArmadoResult = [];
      this.servicios1Box.forEach(element => {
        element.state = false;
      });
      this.servicios2Box.forEach(element => {
        element.state = false;
      });
      this.servicios3Box.forEach(element => {
        element.state = false;
      });
      this.servicios4Box.forEach(element => {
        element.state = false;
      });
      this.servicios5Box.forEach(element => {
        element.state = false;
      });
    },



    selectOption(opc){
      switch(opc){
        case "base":
          this.CleanSelections();
          this.reservacionBase = true;
          this.reservacionPaquete = false;
          this.total = 4950;
          this.showPlaning = false;
          this.totalText = "$" + this.total.toString() + " MXN";
          this.$vuetify.goTo('#opcionesPaquetes');
          this.serviciosPaquete = true;          
          break;
        case "paquete":
          this.$vuetify.goTo('#opcionesPaquetes');
          this.reservacionBase = false;
          this.reservacionPaquete = true;
          this.total = 4950;
          this.showPlaning = true;
          this.serviciosPaquete = true;
          this.totalText = "$" + this.total.toString() + " MXN";
          break;

        default: 
          this.reservacionBase = false;
          this.reservacionPaquete = false;
          this.total = 0;
          this.totalText = "";
          break;
      }

    },
    
    formatDates(dateOne) {
      let formattedDates = ''
      if (dateOne) {
        formattedDates = format(dateOne, this.dateFormat)
        let event= new Date(formattedDates);
        var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        var newformattedDates = event.toLocaleString('es-ES',options);
        this.selectedDay = dateOne;
        this.theDate = newformattedDates;
        //console.log("Selected Date: ", this.selectedDay);
      }
      return newformattedDates
    },
    getFirstDate(){
      var someDate = new Date();
      var numberOfDaysToAdd = 7;
      var result = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
      this.minDate = new Date(result).toISOString().slice(0, 10);
      this.dateOneMonth = (new Date(result).getMonth()) + 1;
    },
    async getNoFreeDates(){
      const db = getFirestore();
      const querySnapshot = await getDocs(collection(db, "reservacionesFechas"));
      querySnapshot.forEach((doc) => {
        this.noFreeDates.push(doc.data().fecha);
        const datePlus = new Date(doc.data().fecha);
        datePlus.setDate(datePlus.getDate() + 1);
        const formatteDatePlus = new Date(datePlus).toISOString().slice(0, 10);
        this.noFreeDates.push(formatteDatePlus);
      });   
    },

    reservaYa(){
      console.log(this.selectedDay)
    }
    
  }
}
</script>

<style scoped>

.reservation-btn{
  width: 300px!important;
}
.font-reservation{
  font-family: 'Montserrat Alternates', sans-serif;
}
.font-reservation-bottom{
  font-family: 'Montserrat Alternates', sans-serif;
  font-weight: 800;
  font-size: 1em;
}

.font-card-reservation{
  font-family: 'Anton', sans-serif;
}
.sub-font-card-reservation{
  font-family: 'Montserrat Alternates', sans-serif;
}
.mx-auto {
  margin: 20px;
  box-shadow: 9px 9px 9px rgba(128,128,128,0.6);
}
.mx-auto:hover {
  box-shadow: 9px 9px 9px rgba(20,20,20,0.6);
}

.float-right{
    position: fixed;
    bottom: 33%;
    right: 25px;
    text-align: center;
    box-shadow: 2px 2px 3px #999;
    z-index: +1;
}

.float-bottom{
    position: fixed;
    bottom: 0px;
    text-align: center;
    box-shadow: 2px 2px 3px #999;
    z-index: +1;
}

.input-calendar{
  font-family: 'Montserrat Alternates', sans-serif;
  font-weight: 600;
  width: 340px!important;
  text-align: center;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
    border-radius: 20px;
    border: 1px solid #00fc76;
    color: #fff;
    height: 30px;
    padding-left: 10px;
}
.input-calendar:hover {
     outline: none;
     border: 1px solid #fff;
     color: #fff;
}

.date-picker-mobile-margins{
  margin-top: 50px;
  padding-left: 0px;
  padding-right: 10px;
}

.text-card-info{
  font-family: 'Poppins', sans-serif;
  font-size: 1em;
  font-weight: 700;
  color: white;
}

</style>
